<template>
  <div>
        <sb-page-header>
      <template v-slot:left>
        <h1>Instellingen</h1>
      </template>
    </sb-page-header>
    <sb-call-out style="width: 600px">
      <template v-slot:left> 👉 </template>
      <template v-slot:right>
       Binnenkort beschikbaar 
      </template>
    </sb-call-out>
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCallOut from '@/components/SbCallOut';
export default {

  components: {
    SbPageHeader,
    SbCallOut,
  },
}
</script>

<style>

</style>