export class ChainDate {
  constructor(...args) {
    this.native = new Date(...args);

    const proxy = new Proxy(this, {
      get(target, prop, receiver) {
        if (typeof prop === 'string' && prop in target.native) {
          if (prop.startsWith('set')) {
            return function () {
              if (arguments[0] instanceof Function) {
                target.native[prop].apply(target.native, [arguments[0](proxy)]);
              } else {
                target.native[prop].apply(target.native, arguments);
              }

              return new ChainDate(target.native);
            };
          }

          return function () {
            return target.native[prop].apply(target.native, arguments);
          };
        }

        return Reflect.get(target, prop, receiver);
      },
    });

    return proxy;
  }
}
