<template>
  <div class="container">
    <sb-page-header>
      <sb-page-header-title>Sessies</sb-page-header-title>
    </sb-page-header>

    <template v-if="hasAccess === true">
      <sb-divider title="Coach sessies" height="20" />

      <sb-gql-table ref="sessionsTable" :config="sessionsTableConfig" :query-options="sessionsQueryOptions"
        data-path="sessions" @loading="sessionsLoading = $event" />

      <sb-divider title="Zelfstandig geoefend" height="20" />

      <sb-gql-table ref="exercisesTable" :config="exercisesTableConfig" :query-options="exercisesQueryOptions"
        data-path="cardExercisesPaginated" @loading="exercisesLoading = $event" />
    </template>

    <template v-if="hasAccess === false">
      <sb-call-out>
        <template #left> ℹ️ </template>
        <template #right>
          Je hebt nog geen toegang tot de sessies. Een schoolbeheerder moet
          eerst toegang verlenen.
        </template>
      </sb-call-out>
    </template>

    <sb-spacer height="200" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SbGqlTable from '@/components/SbTable2/SbGqlTable.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import SbPageHeader from '@/components/SbPageHeader.vue';
import SbCallOut from '@/components/SbCallOut.vue';
import SbPageHeaderTitle from '@/components/SbPageHeaderParts/SbPageHeaderTitle.vue';

export default {
  components: { SbGqlTable, SbPageHeader, SbPageHeaderTitle, SbCallOut },

  mixins: [
    nonReactiveMembersMixin(() => {
      return {
        sessionsTableConfig: new TableConfig({
          columns: [
            {
              key: 'createdAt',
              header: 'Datum',
              sortable: true,
              sort: 'descending',
              data: (row) => new Date(row.createdAt).toLocaleDateString(),
            },
            {
              key: 'problemFocus',
              header: 'Focus',
              data: (row) => row.problemFocus?.title,
            },
            {
              key: 'cards',
              header: 'Kaarten',
              data: (row) =>
                row.cards
                  .map(({ card }) =>
                    [card.number, card.title].filter(Boolean).join(' - '),
                  )
                  .join(', '),
            },
            {
              key: 'track',
              header: 'Begeleider',
              data: (row) =>
                row.track.users.find(({ role }) => role === 'TRACK_COACH')?.user
                  ?.fullName || '',
            },
            {
              key: 'finishedAt',
              header: 'Status',
              data: (row) => (row.finishedAt ? 'Klaar' : 'Bezig'),
            },
          ],
        }),
        exercisesTableConfig: new TableConfig({
          columns: [
            {
              key: 'createdAt',
              header: 'Datum',
              sortable: true,
              sort: 'descending',
              data: (row) => new Date(row.createdAt).toLocaleDateString(),
            },
            {
              key: 'readingLevel',
              header: 'Leesniveau',
            },
            {
              key: 'card',
              header: 'Kaart',
              data: (row) =>
                [row.card.number, row.card.title].filter(Boolean).join(' - '),
            },
            {
              key: 'finishedAt',
              header: 'Status',
              data: (row) => (row.finishedAt ? 'Klaar' : 'Bezig'),
            },
          ],
        }),
      };
    }),
  ],

  data() {
    return {
      sessionsLoading: false,
      exercisesLoading: false,
      hasAccess: undefined,
    };
  },

  mounted() {
    this.$apollo
      .query({
        query: gql`query StudentProgress_GetAccessToSessions {
          getUserById(id: "${this.$user.id}") {
            id
            hasAccessToSessions
          }
          getOrganisationById(id: "${this.$route.params.resourceId}") {
            id
            ... on School {
              showCoachSessions
            }
          }
        }`,
      })
      .then(({ data: { getUserById, getOrganisationById } }) => {
        this.hasAccess = [
          getUserById.hasAccessToSessions === true,
          getOrganisationById.showCoachSessions === true,
        ].includes(true);
      });
  },

  methods: {
    sessionsQueryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter,
      };

      const query = gql`
        query StudentParentsSessions_Sessions(
          $first: Int
          $after: String
          $last: Int
          $before: String
          $filter: SessionsFilter
          $orderBy: SessionsOrderBy
        ) {
          sessions(
            first: $first
            after: $after
            last: $last
            before: $before
            filter: $filter
            orderBy: $orderBy
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                createdAt
                finishedAt
                problemFocus {
                  id
                  title
                }
                cards {
                  id
                  card {
                    id
                    number
                    title
                  }
                }
                track {
                  id
                  users {
                    role
                    user {
                      id
                      fullName
                    }
                  }
                }
              }
            }
          }
        }
      `;

      return { variables, query };
    },

    exercisesQueryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter: {
          ...filter,
          studentId: { in: [this.$user.id] },
          sessionId: { equals: null },
        },
      };

      const query = gql`
        query StudentParentsSessions_CardExercises(
          $first: Int
          $after: String
          $last: Int
          $before: String
          $filter: CardExercisesFilter
          $orderBy: CardExercisesOrderBy
        ) {
          cardExercisesPaginated(
            first: $first
            after: $after
            last: $last
            before: $before
            filter: $filter
            orderBy: $orderBy
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                createdAt
                finishedAt
                readingLevel
                card {
                  id
                  number
                  title
                }
              }
            }
          }
        }
      `;

      return { variables, query };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include max-content-width;
}
</style>
