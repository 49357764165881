<template>
  <div>
    <div class="filters">
      <sb-from-to-input
        v-model="fromTo"
        :default-period="7"
        :clearable="false"
      />
    </div>

    <chart :options="chartOptions" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import CardExercisesPerDay from '@/graphql/queries/CardExercisesPerDay.gql';
import SessionsPerDay from '@/graphql/queries/SessionsPerDay.gql';
import SbFromToInput from '@/components/SbFromToInput.vue';
import { ChainDate } from '@/lib/date-chain';

export default {
  components: {
    Chart,
    SbFromToInput,
  },
  props: {
    trackIds: {
      type: Array,
      default: undefined,
    },
    studentId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      fromTo: undefined,
    };
  },
  computed: {
    cardsChartData() {
      if (!this.cardExercisesPerDay) return [];
      return this.cardExercisesPerDay.map((dataPoint) => {
        return {
          x: new Date(dataPoint.day).getTime(),
          y: dataPoint.count,
        };
      });
    },
    sessionsChartData() {
      if (!this.sessionsPerDay) return [];
      return this.sessionsPerDay.map((dataPoint) => {
        console.log({ dataPoint });
        return {
          x: new Date(dataPoint.day).getTime(),
          y: dataPoint.count,
        };
      });
    },
    chartOptions() {
      if (!this.fromTo) return {};
      const fromTo = this.fromTo;
      const cardsChartData = this.cardsChartData;
      const sessionsChartData = this.sessionsChartData;
      return {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        yAxis: {
          tickInterval: 1,
          title: { text: 'Aantal' },
        },
        xAxis: {
          labels: { format: '{value:%b %e}' },
          // tickInterval: 604800000,
          type: 'datetime',
          min: this.fromTo.fromDate.getTime(),
          max: this.fromTo.toDate.getTime(),
          // tickPositioner: function () {
          //   this.tickPositions[0] = fromTo.fromDate.getTime();
          //   return this.tickPositions;
          // },
        },
        series: [
          {
            data: cardsChartData,
            name: 'Kaarten',
          },
          {
            data: sessionsChartData,
            name: 'Sessies',
          },
        ],
      };
    },
  },
  apollo: {
    cardExercisesPerDay: {
      query: CardExercisesPerDay,
      skip() {
        return !this.fromTo;
      },
      variables() {
        const fromDate = new ChainDate(this.fromTo.fromDate)
          .setHours(0)
          .setMinutes(0)
          .native.toISOString();

        const toDate = new ChainDate(this.fromTo.toDate)
          .setHours(23)
          .setMinutes(59)
          .native.toISOString();

        return {
          filter: {
            trackId: {
              in: this.trackIds,
            },
            finishedAt: {
              gte: fromDate,
              lte: toDate,
            },
            studentId: {
              equals: this.studentId,
            },
          },
        };
      },
    },
    sessionsPerDay: {
      query: SessionsPerDay,
      skip() {
        return !this.fromTo;
      },
      variables() {
        const fromDate = new ChainDate(this.fromTo.fromDate)
          .setHours(0)
          .setMinutes(0)
          .native.toISOString();

        const toDate = new ChainDate(this.fromTo.toDate)
          .setHours(23)
          .setMinutes(59)
          .native.toISOString();

        return {
          filter: {
            trackId: {
              in: this.trackIds,
            },
            createdAt: {
              gte: fromDate,
              lte: toDate,
            },
            userId: {
              equals: this.studentId,
            },
          },
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.type-select {
  width: 140px;
}

.filters {
  margin-bottom: 2rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
