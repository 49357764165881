<template>
  <div class="from-to-input">
    <date-picker
      ref="from"
      format="dd-MM-yyyy"
      :style="{ width: '154px' }"
      :options="{ disabledDate: disabledFromDate }"
      :value="value.fromDate"
      :clearable="clearable"
      @input="$emit('input', { fromDate: $event, toDate: value.toDate })"
    />
    <span class="divider">t/m</span>
    <date-picker
      ref="to"
      format="dd-MM-yyyy"
      :style="{ width: '154px' }"
      :options="{ disabledDate: disabledToDate }"
      :value="value.toDate"
      :clearable="clearable"
      @input="$emit('input', { fromDate: value.fromDate, toDate: $event })"
    />
  </div>
</template>

<script>
import { ChainDate } from '@/lib/date-chain';

export default {
  name: 'SbFromToInput',

  props: {
    defaultPeriod: { type: Number, default: 7 },
    clearable: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      default: function () {
        const toDate = new Date();
        const fromDate = new ChainDate(toDate).setDate(
          (date) => date.getDate() - this.defaultPeriod,
        ).native;
        this.noInitialValue = true;
        return { fromDate, toDate };
      },
      validator: (value) => {
        return 'fromDate' in value && 'toDate' in value;
      },
    },
  },

  mounted() {
    if (this.noInitialValue) this.$emit('input', this.$props.value);
  },

  methods: {
    disabledFromDate(date) {
      if (!this.value.toDate) return false;
      return date.getTime() > this.value.toDate.getTime();
    },

    disabledToDate(date) {
      if (!this.value.fromDate) return false;
      return date.getTime() < this.value.fromDate.getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.from-to-input {
  .divider {
    margin: 0 0.7rem;
  }
}
</style>
