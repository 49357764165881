<template>
  <div>
    <div class="filters">
      <i-select v-model="displayType" class="type-select" style="width: 200px">
        <i-option value="dmtGraph">DMT voortgang</i-option>
        <i-option value="dmt">DMT resultaten</i-option>
        <i-option value="avi">AVI resultaten</i-option>
        <i-option value="misc">Overige resultaten</i-option>
      </i-select>
      <sb-from-to-input v-model="fromTo" :default-period="28" />
    </div>

    <div
      class="chart-container"
      :style="{
        height: ['avi', 'dmt', 'misc'].includes(displayType)
          ? 'auto'
          : undefined,
      }"
    >
      <chart
        v-if="
          displayType === 'dmtGraph' &&
          $apollo.queries.intakeTests.loading === false
        "
        :options="dmtChartOptions"
      />

      <sb-table
        v-if="displayType === 'dmt' && dmtResults"
        :config="dmtTableConfig"
        :data="dmtResults"
        :with-navigation="false"
      />

      <sb-table
        v-if="displayType === 'avi' && aviResults"
        :config="aviTableConfig"
        :data="aviResults"
        :with-navigation="false"
      />

      <sb-table
        v-if="displayType === 'misc' && miscResults"
        :config="miscTableConfig"
        :data="miscResults"
        :with-navigation="false"
      />

      <sb-loading
        v-if="$apollo.queries.intakeTests.loading"
        position="absolute"
        width="100%"
        height="100%"
        :delay="0"
      />

      <sb-spacer height="60" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { readingLevelMixin } from '@/lib/reading-level';
import SbFromToInput from '@/components/SbFromToInput.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbTable from '@/components/SbTable2/SbTable2.vue';
import gql from 'graphql-tag';
import { ChainDate } from '@/lib/date-chain';
import { GraphQL } from '@/lib/graphql';
import { sortByCreatedAt } from '@/lib/sort-by-created-at';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from './SbTable2/TableConfig';
import { aviCardLevelMixin } from '@/lib/avi-card-level';
import { getZelfredzaamheidLabel } from '@/lib/zelfredzaamheid';
import { getDmtCardTypeLabel } from '@/lib/dmt-card-type';

const GRAPH_DATA_NORM_VALUES_DMT = [
  43,
  68,
  130,
  142,
  179,
  194,
  219,
  228,
  251,
  259,
  268,
];

const GRAPH_DATA_NORM_VALUES_AVI = [
  28,
  44,
  85,
  93,
  117,
  127,
  143,
  149,
  164,
  170,
  176,
];

export default {
  name: 'SbStudentProgressGraph',
  components: { Chart, SbFromToInput, SbLoading, SbTable },

  mixins: [
    aviCardLevelMixin,
    readingLevelMixin,
    nonReactiveMembersMixin((self) => {
      const sort = self.aviLevelSort.bind(self);
      sort.applyByDefault = 'ascending';

      const toMinutes = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return [minutes, seconds]
          .map((number) =>
            number.toLocaleString('nl-NL', { minimumIntegerDigits: 2 }),
          )
          .join(':');
      };

      // Afgenomen door
      // Oefenniveau
      // Variant
      // Niveau (Beheersingsniveau, Instructieniveau, Frustratieniveau)
      // Tijd
      // Norm Tijd
      // Fouten
      // Norm Fouten
      const defaultColumns = [
        {
          key: 'testDate',
          header: 'Datum afgenomen',
          data: (row) =>
            row.testDate ? new Date(row.testDate).toLocaleDateString() : '',
        },
        {
          key: 'testSupervisor',
          header: 'Afgenomen door',
          data: (row) => row.testSupervisor,
        },
      ];

      const aviColumns = [
        {
          key: 'level',
          header: 'Oefenniveau',
          sortable: true,
          sort,
        },
        {
          key: 'number',
          header: 'Variant',
          data: (row) => row.number || 'n.v.t.',
        },
        {
          key: 'level_',
          header: 'Niveau',
          data: (row) =>
            row.results?.[0]?.level
              ? self.getAviCardLevelLabel(row.results[0].level)
              : '',
          // width: 120,
        },
        {
          key: 'timeSpent',
          header: 'Tijd',
          data: (row) => toMinutes(row.results?.[0]?.timeSpent ?? 0),
          width: 120,
        },
        {
          key: 'timeNorm',
          header: 'Norm tijd',
          data: (row) => toMinutes(row.results?.[0]?.timeNorm ?? 0),
          width: 120,
        },
        {
          key: 'mistakesMade',
          header: 'Fouten',
          data: (row) => row.results?.[0]?.mistakesMade ?? 0,
          width: 120,
        },
        {
          key: 'mistakesNorm',
          header: 'Norm fouten',
          data: (row) => row.results?.[0]?.mistakesNorm ?? 0,
          width: 120,
        },
      ];

      const dmtColumns = [
        {
          key: 'zelfredzaamheid',
          header: 'Zelfredzaamheid',
          data: (row) => getZelfredzaamheidLabel(row.zelfredzaamheid),
          width: 120,
        },
        {
          key: 'type',
          header: 'Kaarttype',
          data: (row) => (row.type ? getDmtCardTypeLabel(row.type) : '-'),
          width: 120,
        },
        {
          key: 'amountOfWords',
          header: 'Woorden',
          data: (row) => row.amountOfErrors ?? 0,
          width: 120,
        },
        {
          key: 'amountOfErrors',
          header: 'Fouten',
          data: (row) => row.amountOfWords ?? 0,
          width: 120,
        },
      ];

      const miscColumns = [
        {
          key: 'remark',
          header: 'Opmerking',
        },
      ];

      return {
        aviTableConfig: new TableConfig({
          columns: [...defaultColumns, ...aviColumns],
        }),
        dmtTableConfig: new TableConfig({
          columns: [...defaultColumns, ...dmtColumns],
        }),
        miscTableConfig: new TableConfig({
          columns: [...defaultColumns, ...miscColumns],
        }),
      };
    }),
  ],

  props: { studentId: { type: String, default: undefined } },

  data() {
    return {
      displayType: 'dmt',
      fromTo: undefined,
      dmtGraphResults: undefined,
      aviResults: undefined,
      dmtResults: undefined,
      miscResults: undefined,
    };
  },

  computed: {
    dmtOldResults() {
      const levels = Object.values(this.ReadingLevel);
      const results = [];

      levels.forEach((level) => {
        const intakesOfLevel = sortByCreatedAt(
          this.dmtGraphResults?.[level] ?? [],
          'ASC',
        );

        // remove latest result
        intakesOfLevel.pop();

        if (!intakesOfLevel.length) return;

        intakesOfLevel.forEach((intake) => {
          const score = intake.results.reduce(
            (subtotal, { amountOfWords, amountOfErrors }) =>
              subtotal + amountOfWords - amountOfErrors,
            0,
          );

          const data = new Array(levels.length).fill(null);
          data[levels.indexOf(level)] = score;

          results.push({
            name: [
              'Leerling',
              intake.createdAt
                ? new Date(intake.createdAt).toLocaleString()
                : '',
            ].join(' - '),
            color: '#EBA757',
            lineWidth: 0,
            marker: { symbol: 'triangle' },
            showInLegend: false,
            data,
          });
        });
      });

      return results;
    },

    dmtLatestResults() {
      const levels = Object.values(this.ReadingLevel);

      return {
        name: 'Leerling - laatste test',
        data: levels.map((level) => {
          console.log(level, this.dmtGraphResults?.[level]);
          const intakesOfLevel = sortByCreatedAt(
            this.dmtGraphResults?.[level] ?? [],
            'DESC',
            'testDate',
          );

          if (!intakesOfLevel.length) return null;

          const score = intakesOfLevel[0].results.reduce(
            (subtotal, { amountOfWords, amountOfErrors }) =>
              subtotal + amountOfWords - amountOfErrors,
            0,
          );

          return score;
        }),
      };
    },

    dmtChartOptions() {
      return {
        title: false,

        chart: {
          type: 'line',
          style: {
            fontFamily: 'Nunito',
          },
        },

        yAxis: {
          gridLineDashStyle: 'Dash',
          tickPixelInterval: 40,
          title: false,
        },

        xAxis: {
          gridLineWidth: 1,
          tickInterval: 1,
          tickmarkPlacement: 'on',
          labels: {
            formatter: ({ value }) => Object.values(this.ReadingLevel)[value],
          },
        },

        series: [
          {
            color: '#009EFE',
            lineWidth: 3,
            name: 'Norm',
            dashStyle: 'Dash',
            data: GRAPH_DATA_NORM_VALUES_DMT,
          },
          {
            color: '#EBA757',
            lineWidth: 3,
            connectNulls: true,
            marker: { symbol: 'circle' },
            ...this.dmtLatestResults,
          },
          ...this.dmtOldResults,
        ],
      };
    },
  },

  methods: {
    aviLevelSort(a, b, order) {
      const levels = Object.keys(this.ReadingLevel);
      const indexA = levels.indexOf(a.level);
      const indexB = levels.indexOf(b.level);
      if (indexA < indexB) return order === 'ascending' ? -1 : 1;
      if (indexA > indexB) return order === 'ascending' ? 1 : -1;
      return 0;
    },
  },

  apollo: {
    intakeTests: {
      skip() {
        return !this.fromTo;
      },

      variables() {
        const fromDate = new ChainDate(this.fromTo.fromDate)
          .setHours(0)
          .setMinutes(0)
          .native.toISOString();
        const toDate = new ChainDate(this.fromTo.toDate)
          .setHours(23)
          .setMinutes(59)
          .native.toISOString();

        const commonFilter = {
          userId: { equals: this.$route.params.studentId },
          createdAt: { gte: fromDate, lte: toDate },
        };

        return {
          first: GraphQL.MAX_SAFE_INTEGER,
          aviTestsFilter: commonFilter,
          dmtTestsFilter: commonFilter,
          miscTestsFilter: commonFilter,
        };
      },

      query: gql`
        query StudentProgressGraph_IntakeTests(
          $first: Int
          $aviTestsFilter: IntakeAviTestsFilter
          $dmtTestsFilter: IntakeDmtTestsFilter
          $miscTestsFilter: IntakeMiscTestsFilter
        ) {
          intakeAviTests(first: $first, filter: $aviTestsFilter) {
            edges {
              node {
                id
                createdAt
                levels
                testDate
                testSupervisor
                results {
                  id
                  timeSpent
                  timeNorm
                  mistakesMade
                  mistakesNorm
                  level
                }
              }
            }
          }

          intakeDmtTests(first: $first, filter: $dmtTestsFilter) {
            edges {
              node {
                id
                createdAt
                levels
                testDate
                testSupervisor
                zelfredzaamheid
                results {
                  id
                  type
                  amountOfWords
                  amountOfErrors
                }
                trackId
              }
            }
          }

          intakeMiscTests(first: $first, filter: $miscTestsFilter) {
            edges {
              node {
                id
                createdAt
                testDate
                testSupervisor
                remark
              }
            }
          }
        }
      `,

      update(data) {
        const accumulator = () =>
          Object.values(this.ReadingLevel).reduce(
            (acc, level) => ((acc[level] = []), acc),
            {},
          );

        const aviResults = data.intakeAviTests.edges.map(({ node }) => node);

        const _dmtResultsPerTrack = data.intakeDmtTests.edges.map(
          ({ node }) => node,
        );
        const dmtResults = _dmtResultsPerTrack.reduce((acc, cur) => {
          const results = cur.results.map((result) => ({
            ...cur,
            type: result.type,
            amountOfErrors: result.amountOfErrors,
            amountOfWords: result.amountOfWords,
            levels: cur.levels,
            __typename: result.__typename,
          }));
          return [...acc, ...results];
        }, []);

        const miscResults = data.intakeMiscTests.edges.map(({ node }) => node);

        /**
         * // TODO // TOFIX
         * Might be a tmp fix for the graph. Have changed node.level to node.levels[0] because the results support multiple readingLevels now.
         * Not sure how to handle.
         */
        const dmtGraphResults = data.intakeDmtTests.edges.reduce(
          (acc, { node }) => (acc[node.levels[0]].push(node), acc),
          accumulator(),
        );

        this.aviResults = aviResults;
        this.dmtResults = dmtResults;
        this.miscResults = miscResults;
        this.dmtGraphResults = dmtGraphResults;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.type-select {
  width: 140px;
}

.filters {
  margin-bottom: 2rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.chart-container {
  height: 400px;
  position: relative;
}
</style>
