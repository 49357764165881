export const Zelfredzaamheid = {
  REDZAAMHEIDSLEZER: 'REDZAAMHEIDSLEZER',
  REGULIER: 'REGULIER',
  OTHER: 'OTHER',
};

/**
 * Get a human readable label for the corresponding `Zelfredzaamheid` member.
 * @param {string} type
 * @returns {string} label
 */
export function getZelfredzaamheidLabel(type) {
  switch (type) {
    case Zelfredzaamheid.REDZAAMHEIDSLEZER:
      return 'Redzaamheidslezer';
    case Zelfredzaamheid.REGULIER:
      return 'Tempolezer';
    case Zelfredzaamheid.OTHER:
      return 'Overig';
    default:
      throw new Error(`Unknown type ${type}`);
  }
}

export const zelfredzaamheidMixin = {
  data: () => ({ Zelfredzaamheid }),
  methods: { getZelfredzaamheidLabel },
};
