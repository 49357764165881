export const DmtCardType = {
  CARD_1A: 'CARD_1A',
  CARD_1B: 'CARD_1B',
  CARD_1C: 'CARD_1C',
  CARD_2A: 'CARD_2A',
  CARD_2B: 'CARD_2B',
  CARD_2C: 'CARD_2C',
  CARD_3A: 'CARD_3A',
  CARD_3B: 'CARD_3B',
  CARD_3C: 'CARD_3C',
};

function getCardLevel(cardType) {
  return cardType.split('_')[1];
}

/**
 * Get a human readable label for the corresponding `DmtCardType` member.
 * @param {string} type
 * @returns {string} card label
 */
export function getDmtCardTypeLabel(type) {
  if (!DmtCardType[type]) throw new Error(`Unkown type ${type}`);
  return getCardLevel(type);
}

/**
 * Get all DMT Card Types for a given level.
 * @param { string | number } level
 * @returns { Array<string> } array of card type values
 */
export function getDmtCardTypesByLevel(level) {
  return getDmtCardTypesList().filter((type) =>
    getCardLevel(type).startsWith(level),
  );
}

/**
 * Get all DMT card types as array
 * @returns { Array<string> }
 */
export function getDmtCardTypesList() {
  return Object.values(DmtCardType);
}

export const dmtCardTypeMixin = {
  data: () => ({ DmtCardType }),
  methods: { getDmtCardTypeLabel, getDmtCardTypesByLevel, getDmtCardTypesList },
};
