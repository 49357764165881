export const AviCardLevel = {
  BEHEERSING: 'BEHEERSING',
  INSTRUCTIE: 'INSTRUCTIE',
  FRUSTRATIE: 'FRUSTRATIE',
};

/**
 * Get a human readable label for the corresponding `AviCardLevel` member.
 * @param {string} type
 * @returns {string} avi card level label
 */
export function getAviCardLevelLabel(type) {
  if (!AviCardLevel[type]) throw new Error(`Unkown type ${type}`);

  switch (type) {
    case AviCardLevel.BEHEERSING:
      return 'Beheersingsniveau';
    case AviCardLevel.FRUSTRATIE:
      return 'Frustratieniveau';
    case AviCardLevel.INSTRUCTIE:
      return 'Instructieniveau';
  }
  return type[0] + type.substr(1).toLowerCase();
}

export const aviCardLevelMixin = {
  data: () => ({ AviCardLevel }),
  methods: { getAviCardLevelLabel },
};
