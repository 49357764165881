<template>
  <div>
        <sb-page-header>
      <template v-slot:left>
        <h1>Stickers</h1>
      </template>
    </sb-page-header>
        <sb-call-out style="width: 600px">
      <template v-slot:left> 👉 </template>
      <template v-slot:right>
       Binnenkort beschikbaar 
      </template>
    </sb-call-out>
  </div>
</template>

<script>
import SbCallOut from '@/components/SbCallOut';
import SbPageHeader from '@/components/SbPageHeader';
export default {

  components: {
    SbCallOut,
    SbPageHeader,
  },
}
</script>

<style>

</style>