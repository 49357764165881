<template>
  <div class="outer-container">
    <div class="input-container">
      <h1>Hoe ging de oefening?</h1>
      <sb-spacer height="30" />
      <p>Opmerking</p>
      <i-input
        v-model="remark"
        type="textarea"
        placeholder="Eventuele opmerking van/voor de leerling."
      />
    </div>

    <sb-bottom-fixed-buttons :with-side-menu-indent="false" center>
      <template #right>
        <i-button type="primary" size="large" @click="submitRemark"
          >Oefening afronden</i-button
        >
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons.vue';
import gql from 'graphql-tag';

export default {
  components: { SbBottomFixedButtons },
  data: () => ({
    remark: '',
  }),
  methods: {
    async submitRemark() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          variables: {
            input: {
              id: this.$route.query.exerciseId,
              data: { remark: this.remark || '' },
            },
          },
          mutation: gql`
            mutation StudentFinishCardExercise_UpdateExercise(
              $input: FinishCardExerciseInput!
            ) {
              finishCardExercise(input: $input) {
                id
              }
            }
          `,
        });

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }

        this.$router.push({ name: 'Home' }).catch(() => null);
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  width: 100%;
  @include max-content-width(true);
}
</style>
