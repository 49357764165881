<template>
  <div class="sb_page-header-title">
    <h1 v-if="title">{{ title }}</h1>
    <h1 v-else><slot /></h1>
    <div v-if="showDropdownCaret" class="sb_page-header-title_dropdown-caret" />
    <div v-if="amount" class="sb_page-header-title_amount">
      {{ amount }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbPageHeaderTitle',
  props: {
    /**
     * The title, will be placed in a H1.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Show an amount, indicating the possible child selections in the page context.
     */
    amount: {
      type: Number,
      default: null,
    },
    /**
     * Whether to show a dropdown caret, in case of a dropdown.
     */
    showDropdownCaret: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_page-header-title {
  display: flex;

  h1 {
    font-weight: 600;
  }

  //
  //  DROPDOWN CARET
  //
  &_dropdown-caret {
    position: relative;
    padding: 0 15px;
    &:after {
      content: '';
      border-radius: 4px;
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $brand-primary;
      position: absolute;
      right: 0;
      top: 20px;
      transform: translateY(-50%);
    }
  }

  //
  //  AMOUNT
  //
  &_amount {
    padding: 8px 0 0 15px;
    position: relative;
    margin: 0 0 0 30px;
    color: $brand-gray;
    &:before {
      content: '';
      width: 1px;
      height: 40px;
      background: $brand-light-gray;
      position: absolute;
      left: 0;
      top: 20px;
      transform: translateY(-50%);
    }
  }
}
</style>
