<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Voortgang</h1>
      </template>
    </sb-page-header>

    <template v-if="hasAccess === true">
      <sb-divider title="Score voortgang" />

      <sb-student-progress-graph :student-id="$user.id" />

      <sb-divider title="Sessies &amp; Kaarten" />

      <sb-student-cards-progress-graph :student-id="$user.id" />
    </template>

    <template v-if="hasAccess === false">
      <sb-call-out>
        <template #left> ℹ️ </template>
        <template #right>
          Je hebt nog geen toegang tot de voortgang. Een schoolbeheerder moet
          eerst toegang verlenen.
        </template>
      </sb-call-out>
    </template>

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader.vue';
import SbCallOut from '@/components/SbCallOut.vue';
import SbStudentProgressGraph from '@/components/SbStudentProgressGraph.vue';
import SbStudentCardsProgressGraph from '@/components/SbStudentCardsProgressGraph.vue';
import gql from 'graphql-tag';
export default {
  components: {
    SbPageHeader,
    SbStudentProgressGraph,
    SbStudentCardsProgressGraph,
    SbCallOut,
  },

  data: () => ({ hasAccess: undefined }),

  mounted() {
    this.$apollo
      .query({
        query: gql`query StudentProgress_GetAccessToSessions {
          getUserById(id: "${this.$user.id}") {
            id
            hasAccessToSessions
          }
          getOrganisationById(id: "${this.$route.params.resourceId}") {
            id
            ... on School {
              showCoachSessions
            }
          }
        }`,
      })
      .then(({ data: { getUserById, getOrganisationById } }) => {
        this.hasAccess = [
          getUserById.hasAccessToSessions === true,
          getOrganisationById.showCoachSessions === true,
        ].includes(true);
      });
  },
};
</script>

<style></style>
